<template>
  <div>
    <TableComponent :fields="fields" :items="messages" :busy="loading">
      <template #cell(scheduled_at)="slotProps">
        <router-link :to="`history/${slotProps.item.id}`" class="text-primary link-out-side">
          {{ slotProps.data }}
        </router-link>
      </template>
      <template #cell(direction)="slotProps">
        <BadgeComponent variant="info" v-if="slotProps.data == 'outbound'">
          {{ $t('generic-str.send-2') }}
        </BadgeComponent>
        <BadgeComponent variant="success" v-if="slotProps.data == 'inbound'">Resposta
        </BadgeComponent>
      </template>
      <template #cell(body)="slotProps">
        <div v-if="!isMobile" v-tooltip.top="{ content: slotProps.data }"  class="left-aligned">
          {{ slotProps.data }}
        </div>
        <b-button v-else class="popover-message" v-b-popover.hover.top="`${slotProps.data}`" :title="$tc('app.message', 1)">
          {{ $tc('app.message', 1) }}
          <span class="material-symbols-outlined icon-visibility">visibility</span>
          <span class="material-symbols-outlined no-visibility">visibility_off</span>
        </b-button>
      </template>
      <template #cell(status)="slotProps">
        <BadgeComponent variant="light" v-if="slotProps.data == 'accepted'">
          {{ $tc('generic-str.status.lbl-pending', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="light" v-if="slotProps.data == 'scheduled'">
          {{ $tc('generic-str.status.lbl-scheduled', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="light" v-if="slotProps.data == 'queued'">
          {{ $tc('generic-str.status.lbl-queue', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="dark" v-if="slotProps.data == 'sending'">
          {{ $tc('generic-str.status.lbl-sending', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="info" v-if="slotProps.data == 'sent'">
          {{ $tc('generic-str.status.lbl-sent', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="danger" v-if="slotProps.data == 'failed'"
                        v-tooltip.top="`${$t('sms.infos.filters.status.lbl-error')} ${slotProps.item.error_code}: ${slotProps.item.error_message}`">
          {{ $tc('generic-str.status.lbl-failure', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="success" v-if="slotProps.data == 'delivered'">
          {{ $tc('generic-str.status.lbl-delivered', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="danger" v-if="slotProps.data == 'undelivered'"
                        v-tooltip.top="`${$t('sms.infos.filters.status.lbl-error')} ${slotProps.item.error_code}: ${slotProps.item.error_message}`">
          {{ $tc('generic-str.status.lbl-unavailable', 1) }}
        </BadgeComponent>
        <BadgeComponent variant="info" v-if="slotProps.data == 'received'">
          {{ $tc('generic-str.status.lbl-response', 1) }}
        </BadgeComponent>
      </template>
    </TableComponent>
    <PaginationComponent :totalPages="pages" v-model="form.page" @change="fetch"/>
  </div>
</template>

<script>
import PaginationComponent from '@/components/PaginationComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';
import moment from 'moment-timezone';

export default {
  name: 'SmsHistoryTab',
  components: {
    BadgeComponent,
    TableComponent,
    PaginationComponent,
  },
  props: {
    filters: Object,
    startDate: String,
    endDate: String,
  },
  data() {
    return {
      fields: [
        { key: 'scheduled_at', formatter: (value) => moment(value).format('DD/MM/YYYY HH:mm:ss') },
        'direction',
        'from',
        'to',
        'body',
        'segments',
        'status',
      ],
      loading: true,
      messages: [],
      form: {
        id: '',
        status: '',
        from: '',
        to: '',
        scheduled_at: {
          gte: '',
          lte: '',
        },
        order_by: ['scheduled_at', 'desc'],
        page: 1,
      },
      pages: 1,
      exporting: false,
      isMobile: window.innerWidth <= 1200,
    };
  },
  created() {
    this.fetch();
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenSize);
  },
  methods: {
    fetch(page = 1) {
      this.loading = true;
      if (this.$route.params.campaignId) {
        this.filters.campaign_id = this.$route.params.campaignId;
      }
      this.$store
        .dispatch('fetchSmsMessages', {
          filter: {
            ...this.filters,
            scheduled_at: {
              gte: this.startDate,
              lt: this.endDate,
            },
          },
          page,
        })
        .then((response) => {
          this.messages = response.data;
          this.pages = response.last_page;
        })
        .catch((error) => {
          this.$toast.show({
            title: this.$t('app.error'),
            content: error.message,
            type: 'danger',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateScreenSize() {
      this.isMobile = window.innerWidth <= 1200;
    },
  },
};
</script>

<style scoped lang="scss">

@media (max-width: 1200px) {
  .popover-message-destop {
    display: none !important;
  }

  .left-aligned {
  text-align: left;
  padding-left: 0;
}
  .popover-message {
    display: flex;
    background-color: rgba(107, 35, 220, 1) !important;
    border: none;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    align-items: center;
    gap: 0.5rem;
  }

  .popover-message:hover {
    background-color: rgba(107, 35, 220, 0.85) !important;
  }

  .icon-visibility,
  .no-visibility {
    transition: opacity 0.3s, transform 0.3s;
  }

  .icon-visibility {
    display: none;
    opacity: 0;
    transform: translateY(0);
  }

  .no-visibility {
    display: inline-block;
    opacity: 1;
    transform: translateY(0);
  }

  .popover-message:hover .icon-visibility {
    display: inline-block;
    opacity: 1;
  }

  .popover-message:hover .no-visibility {
    display: none;
  }
}
</style>
